import React from 'react';

import chatIconURL from '../assets/images/athy-logo.png';

const ChatToggleButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="fixed bottom-4 right-4 z-50 flex h-16 w-16 items-center justify-center rounded-full bg-orange-500 text-white text-4xl shadow-xl hover:bg-orange-600 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110"
      aria-label="Toggle chatbox"
    >
      {/* Replacing the img tag with an SVG component for better scaling and accessibility */}
      <img src={chatIconURL} alt="Chat" className="w-8 h-8" />
    </button>
  );
};

export default ChatToggleButton;
