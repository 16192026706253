import React, { useState, useRef, useEffect } from "react";
import chatIconURL from "../assets/images/athy-logo.png";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { FaCopy, FaThumbsDown, FaMicrophone, FaEraser } from "react-icons/fa";

const subjects = [
  { id: 1, name: "Math", topics: ["Algebra", "Geometry", "Calculus"] },
  { id: 2, name: "Science", topics: ["Physics", "Chemistry", "Biology"] },
];

const initialChat = [
  {
    id: uuidv4(),
    text: "Welcome to our learning platform! How can I assist you today?",
    sender: "system",
    isLoading: "false",
  },
];

const ChatBox = ({ onClose }) => {
  const [messages, setMessages] = useState(initialChat);
  const [sessionId, setSessionId] = useState("1");
  const [newMessage, setNewMessage] = useState("");
  const [isMaximized, setIsMaximized] = useState(false);
  const [classes, setClasses] = useState([]);
  const [tests, setTests] = useState(null);
  const [topics, setTopics] = useState([]);
  const [selectedTest, setSelectedTest] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [showSubjectList, setShowSubjectList] = useState(false);
  const messagesEndRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const hasFetched = useRef(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
      setNewMessage("");
    }
  };

  const resetConversation = (messageId) => {
    axios.post(process.env.REACT_APP_NODE_API_URL + "/chat/clear", {
      lms_user_id: "1",
      chat_session_id: sessionId,
    });

    setMessages(initialChat);
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_NODE_API_URL}/classes`
        );
        setClasses(response.data.data);
      } catch (error) {
        console.log("Error fetching classess:", error);
      } finally {
      }
    };
    getClasses();
  }, []);

  const getTests = async (classData) => {
    try {
      console.log(classData.classId);
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_API_URL}/tests`,
        {
          class_id: classData.classId,
        }
      );
      setTests(response.data.data);
    } catch (error) {
      console.log("Error fetching tests:", error);
    } finally {
    }
  };

  const getTopics = async (data) => {
    try {
      console.log(data);
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_API_URL}/topics`,
        {
          class_id: data.classId,
          testId: data.testId,
        }
      );
      setTopics(response.data.data);
      console.log(topics);
    } catch (error) {
      setTopics([
        {
          id: 1,
          topic: error.message + ' - LMS Service'
        }
      ]);
      console.log("Error fetching tests:", error.message);
    } finally {
    }
  };

  useEffect(() => {
    if (hasFetched.current) {
      console.log("Skipping duplicate invocation in development");
      return;
    }

    console.log("Effect invoked");
    const getSession = async () => {
      const savedSessionId = localStorage.getItem("sessionId");

      try {
        setIsLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_NODE_API_URL}/sessions`,
          {
            message: newMessage,
            user_id: "1",
            chat_session_id: savedSessionId || null,
          }
        );

        const newSessionId = response.data.sessionId;
        if (newSessionId) {
          localStorage.setItem("sessionId", newSessionId);
          setSessionId(newSessionId);
        }
        let chatMessages = response.data.chatMessages || [];

        if (chatMessages) {
          chatMessages = chatMessages
            .filter((item) => item.type === "chat_messages")
            .map(({ id, attributes: { message, sender_type } }) => ({
              id,
              text: message,
              isLoading: false,
              sender: sender_type,
            }));
          setMessages([...initialChat, ...chatMessages]);

          console.log("Processed chat messages:", chatMessages);
        }
      } catch (error) {
        console.log("Error fetching session:", error);
      } finally {
      }
    };

    getSession();
    hasFetched.current = true;
  }, []);

  const handleLike = (messageId) => {
    console.log(`Liked message with ID: ${messageId}`);
  };

  const handleRegenerate = (messageId) => {
    console.log(selectedTopic);
    console.log(`Regenerated message with ID: ${messageId}`);
  };

  const handleCopy = async (text) => {
    try {
      handleRegenerate("test");
      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const simulateTyping = (message, sender, typingMessageId) => {
    let index = 0;
    const chunkSize = message.length > 50 ? 5 : 1;
    const typingSpeedMs = 10;

    setMessages((msgs) =>
      msgs.map((msg) =>
        msg.id === typingMessageId
          ? { ...msg, text: "", isLoading: false }
          : msg
      )
    );

    const typeInBursts = () => {
      if (index < message.length) {
        const nextChunk = message.substring(index, index + chunkSize);
        index += chunkSize;

        setMessages((msgs) =>
          msgs.map((msg) =>
            msg.id === typingMessageId
              ? { ...msg, text: msg.text + nextChunk, isLoading: false }
              : msg
          )
        );

        setTimeout(typeInBursts, typingSpeedMs);
      }
    };

    typeInBursts();
  };

  const sendMessage = async (message = "") => {
    const userMessageId = uuidv4();
    if (message) {
      message =
        "Please create a summarized comprehensive study guide, ensuring it includes complete and detailed explanations for each of these topics: Emphasize key points and concepts as if teaching the material to ensure thorough understanding. :" +
        message;
    } else {
      setMessages((msgs) => [
        ...msgs,
        {
          id: userMessageId,
          text: message !== '' ? message : newMessage,
          sender: "user",
          isLoading: false,
        },
      ]);
    }

    const typingMessageId = uuidv4();

    setMessages((msgs) => [
      ...msgs,
      { id: typingMessageId, text: "", sender: "system", isLoading: true },
    ]);

    try {
      const response = await axios.post(
        process.env.REACT_APP_NODE_API_URL + "/chat",
        {
          message: message !== '' ? message : newMessage,
          lms_user_id: "1",
          chat_session_id: sessionId,
        }
      );
      const aiMessage = response.data?.data?.message;

      simulateTyping(aiMessage, "system", typingMessageId);
    } catch (error) {
      setMessages((msgs) => msgs.filter((msg) => msg.id !== typingMessageId));
    }

    setNewMessage("");
  };

  const toggleMaximize = () => setIsMaximized(!isMaximized);

  const handleSubjectSelect = (subject) => {
    setSelectedSubject(subject);
    getTests(subject);
    setSelectedTopic(null);
    setShowSubjectList(true);
  };

  const handleTestSelect = (test) => {
    setTopics([
      {
        id: 1,
        topic: 'Loading...'
      }
    ]);
    setSelectedTest(test);
    getTopics(test);
  };

  const handleTopicSelect = (topic) => {
    setSelectedTopic(topic);
    resetConversation();
    if (!Array.isArray(topic)) {
      sendMessage(`Selected topic: ${topic.topic}`, "user");
    }
  };

  const goBackToSubjects = () => {
    setSelectedSubject(null);
    setSelectedTopic(null);
    setShowSubjectList(false);
  };

  const goBackToSelectSubjects = () => {
    setSelectedSubject(null);
    setSelectedTopic(null);
    setTests(null);
    setShowSubjectList(true);
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
    <div
      className={`relative bg-white shadow-lg rounded-lg overflow-hidden ${
        isMaximized
          ? "w-full h-full max-h-full max-w-full"
          : "w-4/5 h-4/5 max-w-4/5 max-h-4/5"
      }`}
    >
      <div className="flex h-full">
        <div className="w-1/5 border-r border-gray-200 bg-gray-200 overflow-auto">
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-4 text-center">Activities</h2>
            {!selectedTest && (
              <>
                {tests ? (
                  <ul>
                    <li
                  className="cursor-pointer py-2 hover:bg-gray-300 rounded w-full text-center mb-4"
                  onClick={goBackToSelectSubjects}
                    >
                      Go Back
                    </li>
                    {tests.map((test) => (
                      <li
                        key={test.testId}
                        className="cursor-pointer py-2 hover:bg-gray-300 rounded"
                        onClick={() => handleTestSelect(test)}
                      >
                        {test.value}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <ul>
                    {showSubjectList ? (
                      <>
                        <li
                  className="cursor-pointer py-2 hover:bg-gray-300 rounded w-full text-center mb-4"
                  onClick={() => goBackToSubjects(true)}
                        >
                          Go Back
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {!showSubjectList ? (
                      <>
                        <li
                          className="cursor-pointer py-2 hover:bg-gray-300 rounded"
                          onClick={() => setShowSubjectList(true)}
                        >
                          Select a class
                        </li>

                      </>
                    ) : (
                      ""
                    )}
                    {showSubjectList &&
                      classes.map((classData) => (
                        <li
                          key={classData.classId}
                          className="cursor-pointer py-2 hover:bg-gray-300 rounded text-md"
                          onClick={() => handleSubjectSelect(classData)}
                        >
                          {classData.value}
                        </li>
                      ))}
                  </ul>
                )}
              </>
            )}
            {selectedTest && (
              <>
                <button
                  onClick={() => setSelectedTest(null)}
                  className="cursor-pointer py-2 hover:bg-gray-300 rounded w-full text-center mb-4"
                >
                  Go Back
                </button>
                <ul>
                  {topics.map((topic) => (
                    <li
                      key={topic.id}
                      className="cursor-pointer py-2 hover:bg-gray-300 rounded"
                      onClick={() => handleTopicSelect(topic)}
                    >
                      {topic.topic}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
          <div
            className={`w-4/5 border-r border-gray-200 relative bg-gray-100`}
          >
            <div className="absolute top-0 right-0 p-4">
              <button
                onClick={toggleMaximize}
                className="text-gray-400 hover:text-gray-600 mr-2"
              >
                <svg
                  className={`h-6 w-6 ${isMaximized ? "block" : "hidden"}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 3h6v6m-6 0L3 21M3 9v12h12"
                  />
                </svg>
                <svg
                  className={`h-6 w-6 ${!isMaximized ? "block" : "hidden"}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 14v6h6M4 4h16v16H4V4zm16 0v6m0-6h-6"
                  />
                </svg>
              </button>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-600"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-col h-full">
              <h2 className="text-xs font-medium text-gray-500 text-center align-middle p-2">
                This conversation is recorded and viewable by admin.
              </h2>
              <div className="p-2 flex-grow overflow-y-auto mt-4 mb-4">
                {messages.map((message) => (
                  <div key={message.id} className={`mb-2`}>
                    {(message.sender === "assistant" ||
                      message.sender === "system") && (
                      <div className="flex flex-col items-start mb-2">
                        <img
                          src={chatIconURL}
                          alt="Admin Profile"
                          className="w-8 h-8 rounded-full mb-2"
                        />
                        <div
                          className="inline-block rounded-lg bg-orange-100 px-4 py-2 text-gray-800 ml-8 relative max-w-3xl"
                          style={{ wordWrap: "break-word" }}
                        >
                          <pre
                            className={`${
                              message.isLoading === true
                                ? "typing-animation"
                                : ""
                            }`}
                            style={{
                              whiteSpace: "pre-wrap",
                              wordBreak: "keep-all",
                              fontFamily:
                                "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                            }}
                          >
                            {message.text}
                          </pre>
                          <div className="mt-3">
                            <button
                              className="mr-2"
                              onClick={() => handleCopy(message.text)}
                            >
                              <FaCopy className="icon" />
                            </button>
                            <button onClick={() => handleLike(message.id)}>
                              <FaThumbsDown className="icon" />
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {message.sender === "user" && (
                      <div className="flex flex-col items-end mb-2 mr-4">
                        <pre
                          className="inline-block rounded-lg bg-blue-100 px-4 py-2 text-gray-800 mr-8 max-w-3xl"
                          style={{
                            whiteSpace: "pre-wrap",
                            wordBreak: "keep-all",
                            fontFamily:
                              "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                          }}
                        >
                          {message.text}
                        </pre>
                        <img
                          src={chatIconURL}
                          alt="User Profile"
                          className="w-8 h-8 rounded-full mb-2"
                        />
                      </div>
                    )}
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>
              <div className="w-full border-t border-gray-200 flex flex-col bg-white">
                <div className="bg-white">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      sendMessage();
                    }}
                    className="flex items-center justify-start w-full"
                  >
                    <textarea
                      className="flex-1 px-4 py-2 border-none focus:outline-none resize-none mr-2"
                      placeholder="Type a message..."
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </form>
                </div>
                <div
                  className="flex justify-between items-center px-4"
                  style={{ maxHeight: "60px" }}
                >
                  <button className="mr-2 focus:outline-none flex items-center">
                    <FaMicrophone className="mr-2 hidden" />
                  </button>
                  <div className="flex justify-right items-center py-2 px-4">
                    <button
                      className="hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 focus:outline-none flex items-center mr-2"
                      onClick={resetConversation}
                    >
                      <FaEraser className="mr-2" /> Reset conversation
                    </button>
                    <button
                      type="submit"
                      className="bg-orange-500 hover:bg-orange-600 text-white rounded-full p-2 focus:outline-none"
                      onClick={(e) => {
                        e.preventDefault();
                        sendMessage();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M14 5l7 7m0 0l-7 7m7-7H3"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBox;

