import React, { useState } from 'react';
import ChatBox from 'components/ChatBox'; // Make sure this path is correct
import ChatToggleButton from 'components/ChatToggleButton';
import './index.css'; // Adjust the path according to your file structure

const App = () => {
  const [showChat, setShowChat] = useState(false);

  const toggleChat = () => setShowChat(!showChat);
  const closeChat = () => setShowChat(false);

  return (
    <div className="App">
      {showChat && <ChatBox onClose={closeChat} />}
      <ChatToggleButton onClick={toggleChat} />
    </div>
  );
};

export default App;
